@import '_Navbar';
@import '_Profile';
@import '_Projects';
@import '_Experience';
@import '_AboutMe';
@import '_ImageSlider';
@import '_Footer';
@import '_Skills';
@import '_ScrollToTop';
@import 'variables';

html {
  scroll-behavior: smooth;
}
body {
  background-color: $color-lightgreen;
  color: $color-darkgreen;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

img {
  max-width: 100%;
}

.profile-container, .projects-container, .experience-container, .about-me-container {
    
  @media screen and (min-width: 449px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  @media screen and (max-width: 1099px) {
    padding: 0 20px 20px;
  }
}

.top-nav, .contact-inner-container {
  max-width: 1200px;
  margin: 0 auto;
}
.skills-container {
  @media screen and (min-width: 449px) {
    padding: 20px;
  }
  @media screen and (max-width: 1099px) {
    padding: 0 20px 20px;
  }
}
.skills-container h1, .projects-container h1, .experience-container h1, .about-me-container h1, .contact-container h1 {
  padding: 20px 20px 8px;
  margin-bottom: 30px;
  text-align: center;
  border-bottom: 1px solid $color-darkgreen;
  font-family: "Orbit", serif;

  @media screen and (max-width: 1099px) {
    padding: 10px 20px;
    margin-bottom: 20px;
  }
}

h3 {
font-family:  "Roboto", serif;
}

.about-me-container h2 {
  font-family:  "Roboto", serif;
  font-weight: 500;
}

a, a:visited {
  color: $color-darkgreen !important;
  font-family:  "Roboto", serif;
  font-weight: 500;
}