.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(151, 158, 154);
  padding: 40px 0;
  margin-top: 30px;
  opacity: 0.8;
  transition: opacity 1s ease-in-out;
}

