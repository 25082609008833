.about-me-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: underline;
  }
}

.about-me-container h2 {
  text-align: center;
  padding-bottom: 30px;
  margin: 0;
  font-size: 16px;

    @media screen and (max-width: 449px) {
      font-size: 16px;
      padding-bottom: 25px;
  }
}

.about-me-container h3 {
  text-align: center;
  font-size: 15px;
  margin: 0;
  padding: 10px 0 10px;

  @media screen and (max-width: 449px) {
    font-size: 13px;
  }
}

.about-me-container span {
  text-decoration: underline;
}


