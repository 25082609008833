.skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.396);
}

#skills-title {
  color: black;
  border-bottom: 1px solid black;
}

.skills-container h2 {
  color: black;
  font-size: 20px;
      
  @media screen and (max-width: 1099px) {
  margin-bottom: 5px;
  }
  @media screen and (max-width: 449px) {
    font-size: 19px;
    margin-bottom: 5px
  }
}

.icons-container {
  display:flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.icons-container img {
  z-index: 0;
  width: 55px;
  filter:invert(1);
  filter:brightness(0);
  margin: 0 5px;
      @media screen and (max-width: 449px) {
    padding-top: 5px;
  }
}

.icons-container img:hover {
  transform: scale(1.1);
  transition: all .2s;
}

.icon-section{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
}

#cloudinary {
  width: 65px
}
#mongodb {
  width: 30px
}
#git {
  width: 80px
}

// #bulma {
//   width: 80px
// }

.icon-text {
  opacity: 0;
  color: black;
  font-size: 15px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  
  @media screen and (max-width: 1099px) {
    opacity: 1;
    padding: 0 5px
}
}

.icon-section :hover .icon-text {
  opacity: 1;
}
