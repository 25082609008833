.slider {
  display: flex;
  width: 500px;
  position: relative;
  height: 380px;
  align-items: center;
  transform: scale(0.92);

    @media screen and (max-width: 449px) {
      height: 230px;
      max-width: 300px;
    }
}

.image {
  width: 100%;
  // max-width: 500px;
  border-radius: 10px;
  @media screen and (max-width: 449px) {
    // height: 200px
  }
}

.right-arrow {
  position: absolute;
  right: 0%;
  font-size: 3rem;
  color: #ffffff62;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  // top: 50%;
  left: 0;
  font-size: 3rem;
  color: #ffffff62;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  position: absolute;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}