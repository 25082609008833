.top-to-btm{
  position: relative;

  @media screen and (max-width: 1099px) {
    display: none
  }
}
.icon-position{
  position: fixed;
  bottom: 20%;
  right: 2%;
  z-index: 20;
}
.icon-style{
  border: 2px solid #ffffffa3;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: $color-darkgreen;
  cursor: pointer;
  animation: mover 1s infinite  alternate;
}
.icon-style:hover{
  animation: none;
  color: $color-darkgreen;
  border: 2px solid $color-darkgreen;
}
@keyframes movebtn {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-30px); }
}
