.projects-container {
  font-family: 'Questrial', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-legend {
  font-size:25px;
  padding: 10px 

}

.project-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  justify-items: center;
  
  h2 {
    font-family:  "Roboto", serif;
    font-size: 20px;
    }
  h3 {
    margin: 5px 0 20px;
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ;
  }
}

.project-box a {
text-decoration: underline;}

.project {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: $color-middlegreen 2px solid;
}

#project1-box {
  grid-column: 1 / 2;
  grid-row: 1;
}

#project2-box {
  grid-column: 2 / 2;
  grid-row: 1;
}

.project p {
  text-align: justify;
  width: 100%;
  padding: 10px;
}

.project img {
  width: 100%;
  height: auto;
  border-radius: 2%;
  background-size: cover;
  box-shadow: 2px 2px 5px black;
}

.new-project {
  border: #d838ce 2px solid;
 legend{
  color: #d838ce;
 }
}