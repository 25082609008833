@import 'variables';

.navbar {
  position: sticky;
  padding-top: 10px;
  top: 0;
  z-index: 3;
  background-color: $color-lightgreen;

  @media screen and (max-width: 1099px) {
    border-bottom: none;
  }
}

.top-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  height: 60px;
  transition: .4s ease-in-out;
}

.menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  font-family: "Orbit", serif;
  font-size: 20px;

  &:last-child {
    padding-bottom: 20px;
  }


  li {
    margin: 0 1rem;
    overflow: hidden;
    transition: all .1s ease-in-out;
    cursor: pointer;
    line-height: 60px;


    &:hover {
      color: rgb(112, 62, 8)
    }


  }
}

// CONTACT TOGGLE
// CONTACT TOGGLE
.contact-toggle {
  display: flex;
  transition: all .4s ease;
  position: absolute;
  visibility: hidden;
  padding: 5px 40px 5px 44px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: $color-lightgreen;

  @media screen and (min-width: 1099px) {
    right: -300px;
    margin-top: -20px;
  }

  @media screen and (max-width: 1099px) {
    padding: 5px 45px 5px 45px;
    opacity: 0;
    top: 90%;

    i.icon {
      margin: 0;
    }
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
    transition: all .4s ease;

    @media screen and (min-width: 1099px) {
      margin-top: -20px;
      right: -2%;
    }

    @media screen and (max-width: 1099px) {
      opacity: 1;
      top: 93%;
      padding-bottom: 15px;

      i.icon {
        margin: 0;
      }
  }
    
  }
}
.contact-toggle div:nth-child(2) {
  margin: 0 20px;
  }
.contact-toggle div:nth-child(3) {
  // padding-right: 2px;
  }


.email-contact-box {
  display: flex; 
  align-items: center;
  position: relative;
  transform: 0.1s ease-in;
  
}
.email-contact-box:hover {
  transform: scale(1.04);

  @media screen and (max-width: 1099px) {
    transform: none
  }
}

i.icon {
  font-size: 28px;

  @media screen and (max-width: 1099px) {
    font-size: 25px;
  }
}

.linked-in-contact-box, .github-contact-box {
  display: flex; 
  cursor: pointer;
  align-items: center;
  transform: 0.1s ease-in;
}

.linked-in-contact-box{
  transform: 0.1s ease-in;
}

.linked-in-contact-box:hover, .github-contact-box:hover {
transform: scale(1.04);

  @media screen and (max-width: 1099px) {
    transform: none
  }
}
// CONTACT TOGGLE ENDS
// CONTACT TOGGLE ENDS


.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 16px;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: $color-darkgreen;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background-color: rgb(41, 61, 74);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media screen and (max-width: 1099px) {
  
  .top-nav {
    display: flex;
    justify-content: flex-start;
    padding-left: 5%;
    width: 100%;
    border: none;
  }

  .menu-button-container {
    display: flex;
    padding: 0 16px 7px
  }

  .menu {
    position: absolute;
    top: 2px;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: $color-lightgreen;
  }

  .menu li {
    display: flex;
    justify-content: center;
    width: 100%;
    color: $color-darkgreen;
    background-color: $color-lightgreen;
    font-size: 20px;
    line-height: normal;

    &:hover {
      animation: none
    }
  }

  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: .4s ease-in-out;
  }
  #menu-toggle:checked ~ .menu li {
    height: 2.5em;
    padding: 0.5em;
    transition: .4s ease-in-out;
  }
}

