.experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid $color-middlegreen;
}
.experience-box {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $color-middlegreen;

  @media screen and (max-width: 1099px) {
    & + & {
      padding-top: 20px;
    }
  }
  @media screen and (min-width: 1100px) {
    padding: 25px 0;
  }
}

.experience-header-section {
  display: flex;
  justify-content: space-between;
  padding: 0 20%;

  @media screen and (max-width: 1099px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
    text-align: center;
  }

  @media screen and (min-width: 1100px) {
    margin-bottom: 10px;
  }
}

.experience-image-box {
  width: 30%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 449px) {
    width: 100%;
  }
}

.experience-container img {
  height: 100px;
  border-radius: 25% 10%;
}

.experience-header-text {
  width: 70%;
  
  @media screen and (max-width: 449px) {
    width: 100%;
  }
}
.experience-header-text h2 {
  font-size: 20px;
  padding: 0 10px 10px;
  margin: 0;

    @media screen and (max-width: 449px) {
    font-size: 19px;
    width: 100%
  }
}

.experience-header-text h3 {
  padding: 0 10px 10px;
  margin: 0;
  font-size: 16px;
  @media screen and (max-width: 449px) {
    font-size: 16px;
  }
}

.experience-box p {
  text-align: justify;
  padding: 0 20%;

@media screen and (max-width: 449px) {
  padding: 0
  }
}

#experience-bottom-box {
  border-bottom: none;
}
